import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { ImagePass } from './ImagePass'

const HomePageImageQuery = (props) => {
  const { data } = props
  const image = data.wordpressPage.acf.background
  if (!image) return null
  return (
    <ImagePass src={image} className="background" alt={image.title} returnBase64={true} />
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query HomePageImageQuery {
        wordpressPage(path: {eq: "/"}) {
          acf {
            background {
              title
              localFile {
                publicURL
                childImageSharp {
                  base64: sizes(base64Width: 1400) {
                    base64
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <HomePageImageQuery data={data} {...props} />}
  />
)
