import React from 'react'
import GatsbyLink from '../../GatsbyLink'
import RenderContent from '../../RenderContent'
import { ImagePass } from '../../Image/ImagePass'
import './LinkTiles.scss'

export const LinkTiles = ({ tiles, title, introContent, siteUrl }) => {
  const tileClassArray = ['One', 'Two', 'Three', 'Four', 'Five', 'Six']
  return (
    <section className="links-tiles">
      <div className="wrap">
        <div className="intro">
          {title && (
            <span
              className="section-title h3"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {introContent && (
            <RenderContent className="intro-text" content={introContent} />
          )}
        </div>

        <div className="links-tiles__grid">
          {tiles &&
            Object.keys(tiles).length > 0 &&
            tiles?.map((cardItem, index) => {
              return (
                <GatsbyLink
                  to={cardItem.link.url}
                  className="links-tiles__grid-item"
                  key={`card-item__${index}`}
                >
                  <div className="links-tiles__grid-item__content">
                    {cardItem?.content ? (
                      <RenderContent content={cardItem.content} />
                    ) : (
                      <span className="h3">{cardItem.link.title}</span>
                    )}
                    {cardItem.icon && cardItem.icon.source_url && (
                      <div className="links-tiles__grid-item__icon">
                        <ImagePass src={cardItem.icon} />
                      </div>
                    )}
                    <span className="action" />
                  </div>
                  {cardItem.image && cardItem.image && (
                    <div className="background">
                      <ImagePass src={cardItem.image} />
                    </div>
                  )}
                </GatsbyLink>
              )
            })}
        </div>
      </div>
    </section>
  )
}
