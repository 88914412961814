import React from 'react';
import GatsbyLink from '../../GatsbyLink';
import RenderContent from '../../RenderContent'
import { ImagePass } from '../../Image/ImagePass'
import './LinkList.scss';

export const LinkList = props => {
  const { links, title, introContent, listView, siteUrl } = props;
  return (
    <section className="links-list">
      <div className="wrap">
        <div className="intro">
          {title && <span className="section-title h3" dangerouslySetInnerHTML={{ __html: (title) }}/>}
          {introContent && <RenderContent className="intro-text" content={introContent}/>}
        </div>
        <div className={`links${listView ? ' list' : ' tiles'}`}>
          {links && links.map((link, index) => (
            link.link && link.link.url &&
              <GatsbyLink className="item" key={index} to={link.link.url.replace(process.env.GATSBY_WP_ADDRESS, siteUrl)} target={link.link.target}>
              <div className="inner">
                <div className={`content${link.description ? ' description' : '' }`}>
                  <div className="wrapper">
                    {link.description && <RenderContent content={link.description}/>}
                    {link.link.title && <button className="action" aria-label="Read more">{link.link.title}</button>}
                  </div>
                </div>
                {link.image && link.image && <div className="background"><ImagePass src={link.image} /></div>}
              </div>
            </GatsbyLink>
          ))}
        </div>
      </div>
    </section>
  );
};
