import React, { Component } from 'react'
import './LogoFeed.scss'
import { Image } from '../../Image'
import Carousel from '../../Carousel'
import GatsbyLink from '../../GatsbyLink'
import RenderContent from '../../RenderContent'

class ThisSlider extends Component {
  render() {
    const { gallery } = this.props
    const settings = {
      container: 'logo-carousel-inner',
      loop: false,
      nav: true,
      controls: true,
      mouseDrag: true,
      gutter: 30,
      items: 5,
      lazyload: true,
      responsive: {
        1600: {
          items: 5,
        },
        1024: {
          items: 4,
        },
        640: {
          items: 3,
        },
        360: {
          items: 2,
        },
      },
      speed: 600,
      autoplay: false,
    }
    return (
      <div className="logo-carousel">
        {gallery && (
          <Carousel settings={settings}>
            {gallery.map((slide, slide_count) => (
              <div key={slide_count} className="slide">
                <div className="aspect">
                  <div className="logo">
                    <Image src={slide} />
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        )}
      </div>
    )
  }
}

export const LogoFeed = props => {
  const { LogoGallery: gallery, title, introContent } = props
  return (
    <div className="logo-feed">
      <div className="inner">
        <div className="intro">
          {title && (
            <span
              className="section-title h3"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {introContent && (
            <RenderContent className="intro-text" content={introContent} />
          )}
        </div>
        <div className="inner">
          <ThisSlider gallery={gallery} />
        </div>
      </div>
    </div>
  )
}
