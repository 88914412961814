import React from 'react'
import './Header.scss'
import { ImagePass } from '../../Image/ImagePass'
import HomePageImageQuery from '../../Image/HomePageImageQuery'
import GatsbyLink from '../../GatsbyLink'
import Carousel from '../../Carousel'
import { decodeEntities } from '../../../utils/helpers'

export const Header = props => {
  const {
    compact,
    globalTitle,
    breadcrumbs,
    header,
    subHeader,
    centre,
    spanTitles,
    slider,
    location,
  } = props

  const settings = {
    container: 'page-carousel',
    nav: true,
    mouseDrag: true,
    items: 1,
    controls: false,
    rewind: true,
    loop: false,
    mode: 'gallery',
    autoplay: globalTitle ? true : false,
  }
  return (
    <section className={`page-header${compact ? ' compact' : ''}`}>
      <div className="page-slider">
        <Carousel settings={settings}>
          {slider.map((slide, slide_count) => (
            <div
              key={slide_count}
              className={`slide${
                !slide.background && location.pathname !== '/' ? ' invert' : ''
              }`}
            >
              {!globalTitle && (
                <div className={`title-block${centre ? ' centre' : ''}`}>
                  <div className="wrap">
                    <div className="inner">
                      {slide_count == 0 ? (
                        spanTitles ? (
                          <span
                            className="title h1"
                            dangerouslySetInnerHTML={{
                              __html: decodeEntities(slide.header),
                            }}
                          />
                        ) : (
                          <h1
                            className="title"
                            dangerouslySetInnerHTML={{
                              __html: decodeEntities(slide.header),
                            }}
                          />
                        )
                      ) : spanTitles ? (
                        <span
                          className="title h1"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.header),
                          }}
                        />
                      ) : (
                        <h2
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.header),
                          }}
                        />
                      )}
                      {slide.subHeader && (
                        <p
                          className="sub-title"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.subHeader),
                          }}
                        />
                      )}
                      {slide.action && (
                        <GatsbyLink
                          to={slide.action.url}
                          className="action"
                          decode={true}
                        >
                          {slide.action.title}
                        </GatsbyLink>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {location.pathname === '/' && slide_count == 0 ? (
                <HomePageImageQuery className="background" />
              ) : (
                <ImagePass src={slide.background} className="background" />
              )}
            </div>
          ))}
        </Carousel>
      </div>

      {globalTitle && (
        <div className={`title-block${centre ? ' centre' : ''}`}>
          <div className="wrap">
            <div className="inner">
              {breadcrumbs && <Breadcrumbs location={location} />}
              {spanTitles ? (
                <span
                  className="title h1"
                  dangerouslySetInnerHTML={{ __html: decodeEntities(header) }}
                />
              ) : (
                <h1
                  className="title"
                  dangerouslySetInnerHTML={{ __html: decodeEntities(header) }}
                />
              )}

              {subHeader && (
                <p
                  className="sub-title"
                  dangerouslySetInnerHTML={{
                    __html: decodeEntities(subHeader),
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  )
}
