import React from 'react'
import './BlockCarousel.scss'
import LazyLoad from 'react-lazy-load'
import RenderContent from '../../RenderContent'
import Carousel from '../../Carousel'
import { ImagePass } from '../../Image/ImagePass'

export const BlockCarousel = ({ introduction, items }) => {
  const settings = {
    container: `block`,
    nav: true,
    mouseDrag: true,
    controls: true,
    loop: true,
    gutter: 20,
    slideBy: 1,
    // autoHeight: true,
    // responsive: {
    //   1080: {
    //     autoHeight: true,
    //   },
    // },
  }

  return (
    <section className="block-carousel">
      <div className="wrap">
        {introduction && (
          <div className="content">
            <RenderContent content={introduction} />
          </div>
        )}
        <div className="slider">
          <Carousel settings={settings} className="carousel">
            {items.map((slide, index) => {
              return (
                <div className="block-carousel__slide" key={index}>
                  <div className="image">
                    <ImagePass src={slide.image} className="background" />
                  </div>
                  {slide.content && <RenderContent content={slide.content} />}
                </div>
              )
            })}
          </Carousel>
        </div>
      </div>
    </section>
  )
}
