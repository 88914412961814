import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import GatsbyLink from '../GatsbyLink'
import './SideNavigation.scss'

export const SideNavigationPass = props => {
  const { pageContext, data } = props
  const { wordpress_id, wordpress_parent } = pageContext
  const pages = data.allWordpressPage.edges
  const relatedPages = pages.filter(i => i.node.wordpress_parent === wordpress_parent)
  const childPages = pages.filter(i => i.node.wordpress_parent === wordpress_id)
  const parentPage = pages.filter(i => i.node.id === wordpress_parent)
  const loopPages = wordpress_parent === 0 ? childPages : relatedPages
  if (loopPages) {
    return (
      <ul className="side-navigation">
        {loopPages && loopPages.map((page, index) =>
          <li key={index}><GatsbyLink to={page.node.link} decode={true}>{page.node.title}</GatsbyLink></li>
        )}
      </ul>
    )
  }
  return null
}

export const SideNavigation = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          allWordpressPage(sort: {order: ASC, fields: menu_order}) {
            edges {
              node {
                id: wordpress_id
                title
                slug
                link
                wordpress_parent
              }
            }
          }
        }
      `}
      render={data => <SideNavigationPass {...props} data={data} />}
    />
  );
}
