import React, { Component, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import './TestimonialSlider.scss'
import Carousel from '../../Carousel'
import { slugify, decodeEntities } from '../../../utils/helpers'
import { ImagePass } from '../../Image/ImagePass'
import { Image } from '../../Image'
import GatsbyLink from '../../GatsbyLink'
import { Testimonials2022Slider } from './Testimonials2022Slider'

const TestimonialItem = props => {
  const { showLogo, slide, className } = props
  return (
    <div
      className={`${className}${
        showLogo ? ' logo-testimonial' : ' testimonial'
      }`}
    >
      <div className="wrap">
        <div className="inner">
          <div className="content">
            {slide.content && (
              <p
                dangerouslySetInnerHTML={{
                  __html: decodeEntities(slide.content),
                }}
              ></p>
            )}
            {slide.acf.name && (
              <div className="name">
                <strong>{decodeEntities(slide.acf.name)}</strong>
              </div>
            )}
            {slide.acf.position && (
              <div className="position">
                {decodeEntities(slide.acf.position)}
              </div>
            )}
          </div>
          <div className="logo">
            {showLogo && slide.acf.logo.source_url && (
              <div className="inner-logo">
                <Image src={slide.acf.logo} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

class TestimonialList extends Component {
  render() {
    const { testimonials, showLogo, title, listView } = this.props
    const settings = {
      container: `${slugify(title)}-slug`,
      nav: true,
      controls: true,
      mouseDrag: true,
      items: 1,
      loop: false,
      mode: 'gallery',
    }
    return (
      <div className={!listView ? 'carousel-wrap' : 'list-wrap'}>
        {!listView && (
          <Carousel settings={settings}>
            {testimonials.map(
              (slide, slide_count) =>
                slide_count < 6 && (
                  <TestimonialItem
                    key={slide_count}
                    slide={slide}
                    showLogo={showLogo}
                    className="slide"
                  />
                )
            )}
          </Carousel>
        )}
        {listView &&
          testimonials.map((slide, slide_count) => (
            <TestimonialItem
              key={slide_count}
              slide={slide}
              showLogo={showLogo}
              className="item"
            />
          ))}
      </div>
    )
  }
}

const TestimonialPass = props => {
  const { title, showLogo, data, listView, image, link, postFilter } = props
  const { allWordpressWpTestimonials } = data
  const { nodes: posts } = allWordpressWpTestimonials
  const [hasMounted, setHasMounted] = React.useState(false)
  const returnPosts =
    posts && postFilter
      ? posts.filter(a => postFilter.includes(a.wordpress_id))
      : posts
  // Render double pass to make sure content is appearing once component has mounted.
  // Currently appears to be an issue with React Hydrating the testimonial information in the slider
  React.useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    return null
  }
  if (!listView) {
    return (
      <Testimonials2022Slider
        {...props}
        testimonials={returnPosts}
        // testimonials={data.allWordpressWpTestimonials.nodes}
      />
    )
  }

  return (
    <div className={listView ? 'testimonial-list' : `testimonial-slider`}>
      <div className="wrap">
        {image && (
          <div className="background">
            <ImagePass src={image} />
          </div>
        )}
        {link && (
          <GatsbyLink
            href={link.url}
            className="action"
            aria-label={link.title}
          >
            {link.title}
          </GatsbyLink>
        )}
        <div className="inner">
          {title && (
            <span className="section-title h3">{decodeEntities(title)}</span>
          )}
          <TestimonialList
            testimonials={returnPosts}
            showLogo={showLogo}
            title={title}
            listView={listView}
          />
        </div>
      </div>
    </div>
  )
}

export const TestimonialSlider = props => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          allWordpressWpTestimonials {
            nodes {
              content
              slug
              title
              wordpress_id
              acf {
                logo {
                  source_url
                }
                name
                position
                projectLink {
                  title
                  url
                }
                featuredImage {
                  source_url
                  localFile {
                    childImageSharp {
                      fixed(width: 1080) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => <TestimonialPass {...props} data={data} />}
    />
  )
}
