import React from 'react';
import loadable from '@loadable/component'

export const BlogFeed = (props) => {
  if (props.contentType === 'project') {
    const RelatedProjects = loadable(props => import(`../../Posts/Related/RelatedProjects.jsx`))
    return <RelatedProjects {...props} />
  }
  if (props.contentType === 'product') {
    const Instagram = loadable(props => import(`../../Posts/Related/RelatedProducts.jsx`))
    return <Instagram {...props} />
  }
  if (props.contentType === 'post') {
    const RelatedPosts = loadable(props => import(`../../Posts/Related/RelatedPosts.jsx`))
    return <RelatedPosts {...props} />
  }
  return null
}
