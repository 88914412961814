import React from 'react';
import RenderContent from '../../RenderContent'
import { ImagePass } from '../../Image/ImagePass';
import Carousel from '../../Carousel';
import './Tabs.scss';

export const Tabs = props => {
  const { tabs } = props;
  const settings = {
    container: `tabs-slug`,
    nav: true,
    controls:false,
    mouseDrag: true,
    items: 1,
    loop: false,
    mode: 'gallery',
    lazyload: true,
  };
  return (
    <section className="tabs">
      <div className="wrap">
      <Carousel settings={settings}>
          {tabs && tabs.map((tab, index) =>
            <div className="tab" key={index}>
              <div className="inner">
                <div className="tab-image">
                  <div className="image-inner">
                    <ImagePass src={tab.tabImage}/>
                  </div>
                </div>
                <div className="tab-content">
                  <div className="scroll">
                    <div className="scroll-inner">
                      <RenderContent content={tab.tabContent}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </Carousel>
      </div>
    </section>
  );
};
